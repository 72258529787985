header {
	position: relative;
}


.logo {
	margin-right: auto;
}



header .flex-container {
	display: flex;
	justify-content: flex-end; 
	align-items: center; /* align vertically */
	padding: 4rem var(--hori-padding);
}

.nav-menu{
	display: flex;
	justify-content: space-between; 
}

.nav-menu a {
	display: inline-block;
	transform: rotate(-25deg);
	margin: 0 1.5em;
	position: relative;
}


/* medium */
@media (max-width: 992px) {
	.logo {
		margin-top: 1em;
	}
	header .flex-container {
		display: flex;
		align-items: flex-start;
		justify-content: space-between; 
	}

    .nav-menu{
		display: flex;
		flex-direction: column;
		justify-content: space-between; 
		margin-top: 0;
	}

	.nav-menu a {
		display: inline-block;
		transform: none;
		margin: 1em 1.5em;
		position: relative;
	}
}

/* small */
@media (max-width: 576px) {
	header .flex-container {
		display: flex;
		flex-direction: column;
		justify-content: space-between; 
	}

	.nav-menu {
		display: flex;
		flex-direction: column;
		justify-content: space-between; 
		margin-top: 1vh;
	}

	.nav-menu a {
		display: inline-block;
		margin: 1em 1.5em;
		position: relative;
		transform: none;
	}
}


/* Hover Line Effect */
.nav-menu a:hover {
	color: var(--text-color);
}


.nav-menu a::after {
	content: "";
	position: absolute;
	background-color: var(--text-color);
	opacity: 0.3;
	height: 0;
	width: 100%;
	left: 0;
	bottom: -0.2em;
	transition: 0.5s;
	z-index: -1;
}

.nav-menu a:hover::after {
	width: 100%;
	height: 120%;
}



.current {
	/* You should able to click current tab to go back to the top level */
	/* pointer-events: none; */
	color: var(--text-color);
	
}

.non-current {
	color: #cacaca;
}


